<template>
  <div class="fans-item__wrapper">
    <div class="fans-item" @click="$emit('select')">
      <div class="fans-item__avatar" v-lazy:background-image="item.avatar"></div>
      <div class="fans-item__info">
        <p>{{item.username}}</p>
        <div v-if="extra">
          <p class="has-text-grey">下單金額 {{item.orderSymbol}}{{item.orderMoney}}</p>
          <p class="has-text-primary">返現 {{item.symbol}}{{item.money}}</p>
        </div>
        <p>{{item.created}}</p>
      </div>
      <div class="fans-item__status">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    extra: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: function () {
        return {
          username: 'XXX',
          created: '10:12 01:20'
        }
      }
    }
  },
  data: () => ({
  }),
  computed: {},
  created () {},
  methods: {}
}
</script>

<style lang="scss">
  .fans-item__wrapper {
    padding: 0 16px;
  }
.fans-item {
  border-bottom: 1px solid #eee;
  padding: 12px 0;
  display: flex;
  align-items: center;
  .fans-item__avatar {
    $size: 48px;
    height: $size;
    width: $size;
    border-radius: 100%;
    border: 1px solid #eee;
    margin-right: 12px;
    background: {
      color: #eee;
      size: cover;
      position: center;
      repeat: no-repeat;
    };
    &[lazy="loading"] {
      background-size: 24px!important;
    }
  }
  .fans-item__info {
    flex: 1;
    text-align: left;
    font-size: 14px;
    p {
      margin-bottom: 6px;
    }
  }
  .fans-item__status {
    display: flex;
    font-size: 14px;
  }
}
</style>
